import React, { Component } from "react";

import Page from "./page";

class PageAbbedAnthros extends Component
{
    render()
    {
        return(
            <Page pageTitle="Abbed Anthros" reference="abbed-anthros" className="page-abbed-anthros">
                Abbed Anthros
            </Page>
        );
    }
}

export default PageAbbedAnthros;