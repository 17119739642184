import React, { memo, FC } from "react";
import { Link } from "react-router-dom";

import {
  HeaderButton
} from "../components";

import "./header.css";

type HeaderProps = {
  reference?: string | undefined;
};

const Header:FC<HeaderProps> = () => {
    return(
        <header className="header">
          <h1 id="title"><Link to="/">Drake Bates<span id="emojis"><span id="emoji1">🦎</span><span id="emoji2">🏳️‍🌈</span><span id="emoji3">💪</span><span id="emoji4">💻</span></span></Link></h1>
          <div className="menu">
            <HeaderButton text="About" href="about" />
            <HeaderButton text="Gallery" href="gallery" />
            <HeaderButton text="Games" href="games" />
            <HeaderButton text="Abbed Anthros" href="abbed-anthros" />
            {/* <HeaderButton text="Leaderboard" href="leaderboard" /> */}
            <HeaderButton text="Conventions" href="conventions" />
            {/* <HeaderButton text="Contact" href="contact" /> */}
          </div>
        </header>
    );
}

export default memo(Header);