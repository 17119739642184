import React, { Component } from "react";

import Page from "./page";

class PageGames extends Component
{
    render()
    {
        return(
            <Page pageTitle="Games" reference="games" className="page-games">
                Games Page
            </Page>
        );
    }
}

export default PageGames;