import React, { Component } from "react";

import Page from "./page";

class PageLeaderboard extends Component
{
    render()
    {
        return(
            <Page pageTitle="Leaderboard" reference="leaderboard" className="page-leaderboard">
                Leaderboard Page
            </Page>
        );
    }
}

export default PageLeaderboard;