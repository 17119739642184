import React, { FunctionComponent, ReactElement, memo } from "react";
import { useUserContext } from "../../../helpers/UserContext";
import { Button } from "react-bootstrap";

import "./style.css";

type Props = {
    children?: ReactElement<any, any>;
}
const CheckBox18:FunctionComponent<Props> = ({ children }) =>
{
	const { user, setUser } = useUserContext();
    function set18(isAdult : boolean) {
        setUser({is18: isAdult, is18Set: true });
    }

    return(<>
    {   !user.is18Set ?
        <div className="check-adult-box">
            <div className="floating-box">
                <h1>Are you over the age of 18 and a legal adult in your country? Some of the content on this website is restricted to adults only. By clicking 'Yes' you certify that you are an adult, and this content will be available for you to see.</h1>
                <Button className="btn" id="yes" onClick={() => set18(true)}>Yes</Button>
                <Button className="btn" id="no" onClick={() => set18(false)}>No</Button>
            </div>
        </div> : children
    }
    </>);
}

export default memo(CheckBox18);