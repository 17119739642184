import React, { memo, FC } from "react";

interface SocialButtonProps
{
    id?: string | undefined;
    className?: string | undefined;
    href?: string | undefined;
    title?: string | undefined;
    text?: string | undefined;
}

const SocialMediaButton:FC<SocialButtonProps> = ({
    id,
    className,
    href,
    title,
    text
}) => {
    return (
        <a href={href} target="_blank" title={title} rel="noreferrer">
            <button id={id} className={className}>{text}</button>
        </a>
    );
}

export default memo(SocialMediaButton);