import React, { memo, FC } from "react";
import { Link } from "react-router-dom";
const classNames = require('classnames');

interface HeaderButtonProps
{
    className?: string;
    href: string | "#";
    text?: string | undefined;
}

const HeaderButton:FC<HeaderButtonProps> = ({
    className,
    href,
    text
}) => {
    return (
        <Link to={`/${href}`}>
            <button className={classNames(className, (window.location.pathname === `/${href}` ? "active" : ""))}>{text}</button>
        </Link>
    );
}

export default memo(HeaderButton);