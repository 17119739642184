import { DumbContextFactory } from "./";

export type UserState = {
  loaded: boolean;
  firstName: string;
  lastName: string;

  is18: boolean;
  is18Set: boolean;
};

const defaultState: UserState = {
  loaded: true,
  firstName: "",
  lastName: "",
  is18: false,
  is18Set: false
};

const {
  DumbContextProvider: UserContextProvider,
  useDumbContext: useContext
} = DumbContextFactory<UserState>("UserContext", defaultState, {
  persist: {
    type: "local",
    key: "user"
  }
});

const useUserContext = () => {
  const { state, setState } = useContext();

  return {
    user: state,
    setUser: (newState: Partial<UserState>) => {
      setState({ ...state, ...newState });
    }
  };
};

export { UserContextProvider, useUserContext };