import React, { Component } from "react";

import Page from "./page";

class PageContact extends Component
{
    render()
    {
        return(
            <Page pageTitle="Contact" reference="contact" className="page-contact">
                Contact Page
            </Page>
        );
    }
}

export default PageContact;