import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { UserContextProvider } from './helpers/UserContext';

import './css/App.css';
import './css/style.css'
import './css/deviantART-gallery.min.css';

import {
  PageHome,
  PageAbout,
  PageGallery,
  PageGames,
  PageAbbedAnthros,
  PageLeaderboard,
  PageConventions,
  PageContact,
  Page404
} from './pages/index';

const RouterMap =
[
{ path: "/", element: <PageHome /> },
{ path: "home", element: <PageHome /> },
{ path: "about", element: <PageAbout /> },
{ path: "gallery", element: <PageGallery /> },
{ path: "games", element: <PageGames /> },
{ path: "abbed-anthros", element: <PageAbbedAnthros /> },
{ path: "leaderboard", element: <PageLeaderboard /> },
{ path: "conventions", element: <PageConventions /> },
{ path: "contact", element: <PageContact /> },
{ path: "*", element: <Page404 /> },
];
const router = createBrowserRouter(RouterMap);

function App() {
  return (
    <UserContextProvider>
        <RouterProvider router={router} />
    </UserContextProvider>
  );
}

export default App;