import React, { FC } from "react";

import Page from "./page";
import { useUserContext } from "../helpers/UserContext";

const avatar = require("../img/about/avatar.jpg");
const avatarName = require ("../img/about/avatar-name.png");

const PageAbout:FC = () =>
{
    const { user } = useUserContext();

    return(
        <Page pageTitle="About" reference="about" className="page-about">
            <div className="avatar-wrapper">
                <img src={avatar} style={{width: 250, height: 250, position: "absolute", left: 40, marginTop: 15, borderRadius: "50%"}} alt="" />
                <img src={avatarName} style={{zIndex: 2, position: "sticky"}} alt="" />
                <h1 style={{color: "white", position: "absolute", marginTop: -174, left: 320, textAlign: "right", zIndex: 3, fontSize: 64}}>DRAKE BATES</h1>
            </div>

            <div style={{paddingLeft: 50}}>
                <p id="drake-info" style={{fontSize: 20, minHeight: 272, maxHeight: 288}}>
                    <span className="orange">Species: Lizard</span><br/>
                    <span className="blue">Color: Orange/Blue</span><br/>
                    <span className="orange">Gender: Male</span><br/>
                    {   user.is18 && <>
                        <span className="blue">Sexual Preference: Gay</span><br/>
                        <span className="orange">Status: Single.</span><br/></>
                    }
                    <span className="blue">Hobbies: Programming & Bodybuilding</span><br/>
                    <span className="orange">Lives In: Hell</span><br/>
                    <span className="blue">Height: 7.5 feet</span><br/>
                    <span className="orange">Weight: 450 lbs.</span><br/>
                    <span className="blue">Bench Press: 700 lbs.</span><br/>
                    {   user.is18 && <>
                        <span className="orange">Cock Size: 11 inches</span><br/>
                        <span className="blue">Produces: 6 Cups of cum!</span><br/></>
                    }
                </p>

                <img src="https://d.furaffinity.net/art/mrd66/1659997439/1659997439.mrd66_drake_512.gif" style={{height: 300, width: 300, position: "absolute", right: `calc(50% - ${user.is18 ? 100 : 180}px)`, transform: "scale(-1,1)", marginTop: -320}} alt="" />

                {   user.is18 &&
                    <a href="https://www.furaffinity.net/view/25936815/" target="_blank" style={{textDecoration:"none"}} rel="noreferrer">
                        <h1>Reference Sheet{user.is18 && " [NSFW]"}</h1>
                        <img src="https://d.furaffinity.net/art/mrd66/1514971316/1514971316.mrd66_thick_and_juicy_[nsfw].png" style={{width: "55%"}} alt="" />
                    </a>
                }

                <a href="https://www.deviantart.com/mrd66/art/Thick-and-Juicy-Reference-723527661" target="_blank" style={{textDecoration:"none"}} rel="noreferrer">
                    <h1>Reference Sheet{user.is18 && " [SFW]"}</h1>
                    <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/8369fe96-20ed-4324-9c6f-004d8cdb5369/dbyrpil-2ff370e5-c0e4-4a8f-a22e-f719454f4cd1.png/v1/fill/w_1024,h_786,q_80,strp/thick_and_juicy__reference__by_mrd66_dbyrpil-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9Nzg2IiwicGF0aCI6IlwvZlwvODM2OWZlOTYtMjBlZC00MzI0LTljNmYtMDA0ZDhjZGI1MzY5XC9kYnlycGlsLTJmZjM3MGU1LWMwZTQtNGE4Zi1hMjJlLWY3MTk0NTRmNGNkMS5wbmciLCJ3aWR0aCI6Ijw9MTAyNCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.JJDtLGdquEP7Hdom1tfVQnOrAm3GQTLWfRS6TT_bWDA" style={{width: "55%"}} alt="" />
                </a>
            </div>

            <p style={{color: "white", position: "fixed", top: 125, height: "73%", right: user.is18 ? 40 : 30, overflow: "hidden", width: (user.is18 ? "35" : "30") + "%", pointerEvents: "none"}}>
            {   user.is18 ?
                "Drake Bates is a highly energetic, muscular, and powerful lizard. Weighing in at a whopping 450 lbs; his stellar body can bench press up to 700 lbs of mass! He stands 7.5 feet tall towering over all who stand before him. As a world class power lifter and body builder he enjoys showcasing his physique to the world! His sex drive is the peak of male performance, his preference of partner: men. Large muscular men are the only type of body that interests him. And once aroused, he is a complete powerhouse in the bedroom, fucking for hours at a time! He is gentle and kind and enjoys cuddling with others, kissing and 69 among other fetishes like nipple play and harnesses are his favorite. His preferred species to have sex with are reptiles and other scalies: dragons, snakes, geckos, and lizards. He dislikes hair very much and loves the smoothness of skin-to-skin contact with other scalies. Plus, the muscle looks bigger without hair getting in the way. He enjoys swimming, body building, cycling, and other forms of solo sports where he can push his athletic performance to the max. His hobbies include weightlifting, programming, video games, making art, and partying. He is single and never had a partner, he enjoys company but has always drifted from society as a lone wolf on his own. The perks allow him the freedom to fuck anyone he wants at any time and any place. If you happen to find yourself in the locker room while he is getting changed, you'll be in for a real treat as his thick 11-inch cock is capable of ejaculating 6 cups of cum at a time, and he is more then happy to empty it in your ass, mouth or on your face! He is a dominate top who loves submissive bottoms, especially if they worship his rock-hard muscle. He is very gay and lives in the 7th level of hell." :
                "Drake Bates is a highly energetic, muscular, and powerful lizard. Weighing in at a whopping 450 lbs; his stellar body can bench press up to 700 lbs of mass! He stands 7.5 feet tall towering over all who stand before him. Large muscular bodies are the only type of body that interests him. As a world class power lifter and body builder he enjoys showcasing his physique to the world! He is gentle and kind and enjoys cuddling with others. He dislikes hair very much and loves the smoothness of his scalie body. Plus, the muscle looks bigger without hair getting in the way. He enjoys swimming, body building, cycling, and other forms of solo sports where he can push his athletic performance to the max. His hobbies include weightlifting, programming, video games, making art, and partying. He is single and never had a partner, he enjoys company but has always drifted from society as a lone wolf on his own. Drake lives in the 7th level of hell."
            }
            </p>
        </Page>
    );
}

export default PageAbout;