import React, { Component } from "react";
import Page from "../page";

import "./style.css";

const banner1 = require("../../img/banner-1.png");
const banner2 = require("../../img/banner-2.png");
const banner3 = require("../../img/banner-3.png");
const banner4 = require("../../img/banner-4.png");

class PageHome extends Component
{
    render()
    {
        return(
            <Page pageTitle="Home" reference="home" className="page-home">
                <div style={{display: "flex", height: 520, width: "97vw", justifyContent: "center"}}>
                    <div className="banner-button">
                        <a href="/about">
                            <img src={banner1} alt="" />
                            <div className="banner-text">About</div>
                        </a>
                    </div>
                    <div className="banner-button">
                        <a href="/gallery">
                            <img src={banner2} alt="" />
                            <div className="banner-text">Gallery</div>
                        </a>
                    </div>
                    <div className="banner-button">
                        <a href="/games">
                            <img src={banner3} alt="" />
                            <div className="banner-text">Games</div>
                        </a>
                    </div>
                    <div className="banner-button">
                        <a href="/contact">
                            <img src={banner4} alt="" />
                            <div className="banner-text">Contact</div>
                        </a>
                    </div>
                </div>
            </Page>
        );
    }
}

export default PageHome;