import React, { Component } from "react";
import { deviantARTGalleryPlugin } from "../plugins/deviantART-gallery-plugin";

import Page from "./page";

class MountCallbackComponent extends Component<{onMount?: () => void}>
{
    componentDidMount() {
        this.props.onMount?.(); //invoke callback
    }
}

class Gallery extends MountCallbackComponent
{
    render() {
        return <div id="deviantART-gallery"/>
    }
}

class PageGallery extends Component
{
    loadGallery() {
        deviantARTGalleryPlugin('mrd66', '58846330', '16:9', 50);
        //console.log(gallery);
        //if(!gallery)
        //    gallery = deviantARTGalleryPlugin('mrd66', '58846330', '16:9');
            //this.render();
    }

    //componentDidMount() {
    //    const script = document.createElement("script");
    //    script.src = "deviantART-gallery-plugin.min.js";
    //    script.async = true;
    //    script.onload = () => this.scriptLoaded();
    //
    //    document.body.appendChild(script);
    //    document.body.appendChild(
    //        <script>
    //            deviantARTGalleryPlugin('mrd66', '58846330', '16:9');
    //        </script>
    //    );
    //}
    //
    //scriptLoaded() {
    //    //deviantARTGalleryPlugin('mrd66', '58846330', '16:9');
    //    //window.A.sort();
    //}

    render()
    {
        return(
            <Page pageTitle="Gallery" reference="gallery" className="page-gallery" style={{overflow: "hidden"}}>
                {/* Deviant Art Gallery Embed */}
                <Gallery onMount={this.loadGallery} />

                {/*<script src="deviantART-gallery-plugin.min.js"></script>
                {/*<script>*/}
                {/*</script>*/} 
            </Page>
        );
    }
}

export default PageGallery;