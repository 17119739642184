import React, { Component } from "react";

import Page from "./page";
import { Link } from "react-router-dom";

type convention = {
    name: string;
    link?: string;
}

const linkFurEh = "https://www.fureh.ca/";
const linkVancoufur = "https://vancoufur.org/";
const linkHowl = "https://howloween.ca/";
const linkMegaplex = "https://megaplexcon.org/";
const conventions:convention[] = [
    { name: "FurEh! 2016", link: linkFurEh },
    { name: "Vancoufur 2017", link: linkVancoufur },
    { name: "FurEh! 2017", link: linkFurEh },
    { name: "Vancoufur 2018", link: linkVancoufur },
    { name: "FurEh! 2018", link: linkFurEh },
    { name: "Howloween 2018", link: linkHowl },
    { name: "Vancoufur 2019", link: linkVancoufur },
    { name: "FurEh! 2019", link: linkFurEh },
    { name: "Howloween 2019", link: linkHowl },
    { name: "Megaplex 2023", link: linkMegaplex },
];
const plannedConventions:convention[] = [
    { name: "FurEh! 2024", link: linkFurEh },
    { name: "Megaplex 2024", link: linkMegaplex },
];

class PageConventions extends Component
{
    render()
    {
        return(
            <Page pageTitle="Conventions" reference="conventions" className="page-conventions">
                <h1>Conventions Attended</h1>
                <ul>
                {   conventions.map(con =>
                    <Link to={con.link ?? "#"} target="_blank"><li>{con.name}</li></Link>)
                }
                </ul>
                <h1>Hoping To Attended</h1>
                <ul>
                {   plannedConventions.map(con =>
                    <Link to={con.link ?? "#"} target="_blank"><li>{con.name}</li></Link>)
                }
                </ul>
            </Page>
        );
    }
}

export default PageConventions;