import React, { Component } from "react";
import { Helmet } from "react-helmet";

import {
    Header,
    Footer,
    CheckBox18
} from "../views";

var classNames = require('classnames');

type PageProps<T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>> = T extends
React.JSXElementConstructor<infer P> ? P
: T extends keyof JSX.IntrinsicElements ? JSX.IntrinsicElements[T]
: {
    
};

class Page extends Component<PageProps<React.JSXElementConstructor<any>>, {}>
{
    render()
    {
        return(<>

        {/*<HelmetProvider>*/}
        <Helmet>
            <title>{process.env.REACT_APP_WEBSITE_NAME + (this.props.pageTitle ? (" | " + this.props.pageTitle) : "")}</title>
            <meta
                name="description"
                content={process.env.REACT_APP_DESCRIPTION}
                />
        </Helmet>
        {/*</HelmetProvider>*/}
        
        <CheckBox18>
            <div className={classNames(this.props.className)}>
                {   !this.props.hideHeader &&
                    <Header reference={this.props.reference} />
                }

                <div className="container" style={this.props.style}>
                    {this.props.children}
                </div>

                {   !this.props.hideFooter &&
                    <Footer />
                }

                {/*<CookieNotice />*/}
            </div>
        </CheckBox18>
        </>);
    }
}

export default Page;