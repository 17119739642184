import React, { Component } from "react";

import Page from "./page";

const gif = require('../img/404.gif');

class Page404 extends Component
{
    render()
    {
        return(
            <Page pageTitle="" reference="404" className="page-404">
                <h1 style={{textAlign: "center", marginTop: 100, fontSize: 64}}>Error 404</h1>
                <img className="error-404-gif" src={gif} alt="" />
            </Page>
        );
    }
}

export default Page404;