import React, { memo, FC } from "react";
import { useUserContext } from "../../helpers/UserContext";

import "./footer.css";

import {
	SocialMediaButton
} from "../components/index";

const Footer:FC = () => {
	const { user, setUser } = useUserContext();

    return (
        <div className="footer">
			<h4 id="copyright"><span onClick={() => setUser({is18: !user.is18, is18Set: false})}>Ⓒ</span> {new Date().getFullYear()} Drake Bates</h4>
			<div className="social-media-tray">
				<SocialMediaButton
					id="twitter"
					title="Twitter"
					text="T"
					href="https://twitter.com/intent/follow?ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5EDrakeBatesMrD66&region=follow_link&screen_name=DrakeBatesMrD66"
					/>
				<SocialMediaButton
					id="discord"
					title="Discord"
					text="D"
					href="https://discord.gg/YB7QHPxXJA"
					/>
				<SocialMediaButton
					id="telegram"
					title="Telegram"
					text="T"
					href="https://t.me/drakebatesfitness"
					/>
				{	user.is18 === true && <>
					<SocialMediaButton
						id="patreon"
						title="Patreon"
						text="P"
						href="https://www.patreon.com/AbbedAnthros"
						/>
					<SocialMediaButton
						id="subscribestar"
						title="Subscribe Star"
						text="SS"
						href="https://subscribestar.adult/abbed-anthros"
						/>
				</>}
				<SocialMediaButton
					id="furaffinity"
					title="Fur Affinity"
					text="FA"
					href="https://www.furaffinity.net/user/mrd66"
					/>
				<SocialMediaButton
					id="deviantart"
					title="Deviant Art"
					text="DA"
					href="https://www.deviantart.com/mrd66"
					/>
				<SocialMediaButton
					id="sofurry"
					title="So Furry"
					text="SF"
					href="https://sinripreptile.sofurry.com/"
					/>
				<SocialMediaButton
					id="weasyl"
					title="Weasyl"
					text="W"
					href="https://www.weasyl.com/~mrd66"
					/>
				<SocialMediaButton
					id="mastodon"
					title="Mastodon"
					text="M"
					href="https://meow.social/@DrakeBates"
					/>
				<SocialMediaButton
					id="itaku"
					title="Itaku"
					text="I"
					href="https://itaku.ee/profile/drakebates"
					/>
				<SocialMediaButton
					id="e621"
					title="e621"
					text="E621"
					href="https://e621.net/users/200971"
					/>
			</div>
		</div>
    );
}

export default memo(Footer);