export function deviantARTGalleryPlugin(username:string, id:string, ratio:string, count: number = 10) {
    //console.log(username);
    //console.log(id);
    //console.log(ratio);
    
    // Inject the gallery markup
    var gallery = document.getElementById('deviantART-gallery');
    if (!gallery) return; //gallery not found on this page
    //console.log("gallery found");
    gallery.innerHTML = '<div id="ss__wrapper"></div><div id="ss__controls"><div id="ss__prev"><div id="ss__prevChev"></div></div><div id="ss__next"><div id="ss__nextChev"></div></div><div id="ss__dots"></div></div></div>';
    //console.log(gallery);

    var deviations:any[] = [];

    (function queryYQL() {
        // thanks http://stackoverflow.com/a/7369516/1696757
        var url = 'http://backend.deviantart.com/rss.xml?q=gallery:' + username + '/' + id;
        var apiKey = "jmbhlfn41vfu38er4qw3fncimts6hr3oky2d0rpj";

        (window as any)['callback'] = callback;
        var s = document.createElement('script');
        s.src = "https://api.rss2json.com/v1/api.json?callback=callback&rss_url=" + encodeURI(url) + "&api_key=" + apiKey + "&count=" + count;
        document.body.appendChild(s);
        //console.log(s.src);

        function callback(json:any) {
            var items = json.items;

            for(var i = 0; i < items.length; i++) {
                var object:any = {};

                object.title = items[i].title;
                object.link = items[i].link;
                object.image = items[i].enclosure.link;

                deviations.push(object);
            }

            // async function is complete, move on
            var images = '';

            for(i = 0; i < deviations.length; i++) {
                images +=
                '<a href="' + deviations[i].link + '" noreferrer target="_blank">' +
                    '<img src="' + deviations[i].image + '" alt="' + deviations[i].title + '"/>' +
                '</a>';
            }

            var ssWrapper = document.getElementById('ss__wrapper');
            if(ssWrapper)
                ssWrapper.innerHTML = images;

            simpleslider(ratio);
        }
    })();

    return gallery;
}

/*
  SimpleSlider v1.2 by JaL Productions
  http://jalproductions.co.uk/
  https://github.com/jamesl1001/simpleslider
*/

function simpleslider(ssR:string, ssF?:number, ssD?:any, ssP?:any) {
    // Setup variables
    var ss              = document.getElementById('deviantART-gallery'),
        ssWrapper       = document.getElementById('ss__wrapper'),
        ssControls      = document.getElementById('ss__controls'),
        ssPrev          = document.getElementById('ss__prev'),
        ssNext          = document.getElementById('ss__next'),
        ssDots          = document.getElementById('ss__dots'),
        ssImages        = ssWrapper?.getElementsByTagName('img'),
        ssFrames        = ssF || (ssImages?.length ?? 0),
        ssRatio         = ssR,
        ssDirectory     = ssD,
        ssPrefix        = ssP,
        ssCurrentFrame  = 0,
        ssDotsWidth     = ssFrames * 20,
        ssWidth         = 0,
        ssHeight        = 0;

    // Calculate aspect ratio
    var ssRatioSplit      = ssRatio.split(':');
    var ssRatioPercentage = parseInt(ssRatioSplit[1]) / parseInt(ssRatioSplit[0]) * 100;

    // Set dimensions
    if(ss)
    {
        ss.style.scale = "0.68";
        ss.style.top = "-130px";
        if(ssWrapper)
            ss.style.paddingBottom = ssWrapper.style.paddingBottom = ssRatioPercentage + '%';
    }
    if(ssDots)
        ssDots.style.width     = ssDotsWidth + 'px';

    // Get pixel dimensions
    function getSSDimensions() {
        if(!ssWrapper) return;
        ssWidth  = ssWrapper.offsetWidth;
        ssHeight = ssWrapper.offsetHeight;
    }

    getSSDimensions();

    // Generate navigation dots
    for(var i = 0; i < ssFrames; i++) {
        var ssDot = document.createElement('div');
        ssDot.className = 'ss__dot' + ' ss__frame' + [i];
        if(ssDots)
            ssDots.appendChild(ssDot);
    }

    let ssAllDots = ssDots?.getElementsByTagName('div');

    // Create img elements if they don't already exist on the DOM
    if(ssImages?.length === 0) {
        for(i = 1; i <= ssFrames; i++) {
            var ssImg = new Image();
            ssImg.src = ssD + '/' + ssP + i + '.jpg'; // 'img/directory/prefix1.jpg'
            if(ssWrapper)
                ssWrapper.innerHTML += ssImg.outerHTML;
        }
        if(ssWrapper)
            ssImages = ssWrapper.getElementsByTagName('img');
    }

    for(i = 0; i < (ssImages?.length ?? 0); i++) {
        if(ssImages?.[i])
            coverImages(ssImages?.[i]);
    }

    // Ensure each image fills the wrapper leaving no whitespace (background-size:cover)
    function coverImages(imgElem:HTMLImageElement) {

        var img = new Image();
        img.src = imgElem.src;

        var wait = setInterval(function() {
            if(img.width !== 0 && img.height !== 0) {
                clearInterval(wait);

                // Stretch to fit
                imgElem.className += ((img.width / img.height) < (ssWidth / ssHeight)) ?
                ' full-width' :
                ' full-height';

                calculateCentre();
            }
        }, 0);
    }

    function calculateCentre() {
        for(var i = 0; i < (ssImages?.length ?? 0); i++) {
            if(!ssImages?.[i]) continue;
            //if(ssImages[i].width >= ssWidth) {
                //ssImages[i].style.left = (ssWidth - ssImages[i].width) / 2 + 'px';
            //}

            if(ssImages[i].height >= ssHeight) {
                ssImages[i].style.top = (ssHeight - ssImages[i].height) / 2 + 'px';
            }

            ssImages[i].style.height = "100%";
            ssImages[i].style.width = "auto";
            ssImages[i].style.position = "absolute";
            ssImages[i].style.left = "50%";
            ssImages[i].style.translate = "-50%";
        }
    }

    // Add current class to first frame
    function addCurrent(n:number) {
        if(ssImages?.[n])
            ssImages[n].className += ' current';
        if(ssAllDots?.[n])
            ssAllDots[n].className += ' current';
    }

    // Clear all current classes
    function clearCurrent() {
        for(var i = 0; i < ssFrames; i++) {
            if(ssImages?.[i])
                ssImages[i].className = ssImages[i].className.replace(/ current/, '');
            if(ssAllDots?.[i])
                ssAllDots[i].className = ssAllDots[i].className.replace(/ current/, '');
        }
    }

    // Update current frame
    function goToFrame(n:number) {
        if(n >= ssFrames) {
            ssCurrentFrame = 0;
        } else if(n < 0) {
            ssCurrentFrame = ssFrames - 1;
        } else {
            ssCurrentFrame = n;
        }
    }

    // Always initialize first image as .current
    addCurrent(0);

    // Next and Previous click handlers
    ssPrev?.addEventListener('click', clickPrev);
    ssNext?.addEventListener('click', clickNext);
    
    // Next and Previous style
    if(ssPrev)
    {
        ssPrev.style.width = "16%";
        ssPrev.style.scale = "2";
    }
    if(ssNext)
    {
        ssNext.style.width = "16%";
        ssNext.style.scale = "2";
    }

    function clickPrev() {
        clearCurrent();
        goToFrame(ssCurrentFrame - 1);
        addCurrent(ssCurrentFrame);
    }

    function clickNext() {
        clearCurrent();
        goToFrame(ssCurrentFrame + 1);
        addCurrent(ssCurrentFrame);
    }

    // Navigation dots click handlers
    for(i = 0; i < (ssFrames ?? 0); i++) {
        if(ssAllDots)
            ssAllDots[i].addEventListener('click', clickDots);
    }

    function clickDots(e:any) {
        var dotClicked = "";
        if(e.target) {
            dotClicked = e.target.className;
        } else if(e.srcElement) {
            dotClicked = e.srcElement.className;
        }
        var n = dotClicked.match(/\d+/)?.[0] ?? "0";
        var frame = parseInt(n);
        clearCurrent();
        goToFrame(frame);
        addCurrent(frame);
    }

    document.onkeydown = function(e) {
        switch(e.key) {
            case "ArrowLeft":
            case "a":
            case "A":
                clickPrev();
                break;
            case "ArrowRight":
            case "d":
            case "D":
                clickNext();
                break;
        }
    };

    // Recalculate image centres on window resize
    window.addEventListener('resize', windowResize);

    function windowResize() {
        getSSDimensions();
        calculateCentre();
    }

    //start at random place
    const start = Math.floor(Math.random() * ssFrames);
    clearCurrent();
    goToFrame(start);
    addCurrent(start);
}